.filter-tags {
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: row;
  gap: 1em;

  .filter-tag {
    padding: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    color: inherit;
    text-decoration: none;
    background-color: #ddd;

    &-icon,
    &-label {
      pointer-events: none;
    }

    &.active,
    &:hover {
      background-color: #aaa;
    }
  }
}

