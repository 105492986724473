// site-switcher
.site-switcher {
  .navbar-nav {
    &.language {
      flex-direction: row;
      .nav-item {
        + li {
          margin-left: 5px;
        }
        .nav-link {
          display: block;
          @extend .p-0;
          border-radius: 100%;
          border: 1px solid transparent;
          img {
            display: block;
            width: 22px;
            height: 22px;
          }
          &:hover:not(.active) {
            border-color: $white;
            box-shadow: 0 0 6px rgba($black, 0.3);
          }
        }
        .dropdown-menu {
          padding: 0;
          .dropdown-item {
            padding: 0.5rem;
            img {
              max-width: none;
            }
          }
        }
      }
    }
    .dropdown-menu {
      min-width: 0;
    }
  }
}
