// btn
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	border-radius: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 23px;

	@include media-breakpoint-up(xl) {
		min-width: 240px;
		padding: 20px 40px;
		font-size: 20px;
	}

	// btn-primary
	&.btn-primary {
		@extend .arrow-after-white;

		&:hover {
			background-color: $gray;
			border-color: $gray;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}

	}

	// btn-secondary
	&.btn-secondary {
		@extend .arrow-after-white;

		&:hover {
			background-color: $black;
			border-color: $black;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	text-decoration: none;

	&::after {
		content: "";
		display: block;
		width: 30px;
		height: 14px;
		background-image: url('/images/arrow-pink.svg');
		background-size: 30px 14px;
		transition: 0.5s;
	}

	&:hover {
		&::after {
			transform: translateX(5px);
		}
	}
}

.card {
	&.bg-dark {
		.card-btn {
			&::after {
				background-image: url('/images/arrow-white.svg');
			}
		}
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
