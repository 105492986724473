.gallery {
  // default .gallery
  & {
    @include make-row();
    margin: 0 -5px -10px -5px;

    @include media-breakpoint-up(sm) {
      margin: 0 -10px -20px -10px;
    }

    .gallery-item {
      @include make-col-ready();
      @include make-col(6);
      margin: 0 0 10px 0;
      padding: 0 5px;
      transition: $transition-base;

      @include media-breakpoint-up(sm) {
        margin: 0 0 20px 0;
        padding: 0 10px;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      .gallery-link {
        position: relative;
        @extend .d-block;
        overflow: hidden;
      }

      .gallery-img {
        @extend .img-fluid;
        @extend .w-100;
        transition: 0.5s ease-in-out;
      }

      &:hover {
        .gallery-img {
            transform: scale(1.05);
        }
      }

      .gallery-caption {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;

        @extend .align-items-center;
        @extend .justify-content-center;

        background: rgba($black, .5);
        font-size: $font-size-base * 1.5;
        color: $white;

        &::before {
          content: '+';
        }

        @include media-breakpoint-up(sm) {
          font-size: $font-size-base * 2;
        }
      }

      &.large-item {
        @include make-col(12);
      }

      &.first-item {
        .gallery-caption {
          @extend .d-none;
        }
      }

      &.before-last-item {
        .gallery-caption {
          @extend .d-flex;
          @extend .d-lg-none;
        }
      }

      &.last-item {
        @extend .d-none;
        @extend .d-lg-flex;

        .gallery-caption {
          @extend .d-none;
          @extend .d-lg-flex;
        }
      }
    }

    .gallery-hidden {
      @extend .d-none;
    }
  }

  // .gallery-grid
  &-grid {
    @include make-row();

    margin: 0 -15px -30px -15px;

    .gallery-item {
      @include make-col-ready();
      @include make-col(12);

      margin: 0 0 30px 0;
      transition: $transition-base;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }

      .gallery-link {
        position: relative;
        @extend .d-block;
        overflow: hidden;
      }

      .gallery-img {
        @extend .img-fluid;
        @extend .w-100;
        transition: 0.5s ease-in-out;
      }

      &:hover {
        .gallery-img {
            transform: scale(1.05);
        }
      }

      .gallery-caption {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;

        @extend .align-items-center;
        @extend .justify-content-center;

        background: rgba($black, .5);
        font-size: $font-size-base * 1.5;
        color: $white;
      }
    }
  }

  // .gallery-horizontal
  &-horizontal {
    @include make-row();
    margin: 0 -5px;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      margin: 0 -10px;
    }

    .gallery-label {
      position: absolute;
      z-index: 10;
    }

    .gallery-thumbs {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
      display: flex;
      flex-wrap: wrap;
    }

    .gallery-item {
      @include make-col-ready();
      @include make-col(6);
      margin: 0 0 13px 0;
      padding: 0 5px;

      transition: $transition-base;

      @include media-breakpoint-up(sm) {
        padding: 0 10px;
      }

      .gallery-link {
        position: relative;
        @extend .d-block;
        overflow: hidden;
      }

      .gallery-img {
        @extend .img-fluid;
        @extend .w-100;
        transition: 0.5s ease-in-out;
      }

      &:hover {
        .gallery-img {
            transform: scale(1.05);
        }
      }

      .gallery-caption {
        @extend .d-none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;

        @extend .align-items-center;
        @extend .justify-content-center;

        background: rgba($black, .5);
        font-size: $font-size-base * 1.5;
        color: $white;

        &::before {
          content: '+';
        }

      }

      &.large-item {
        @include make-col(12);
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
      }

      &.last-item {
        .gallery-caption {
          @extend .d-flex;
          font-size: 40px;
        }
      }

    }

    .gallery-hidden {
      @extend .d-none;
    }
  }
}
