@if $use-html-fontsize-reset {
  // forces default html font size of 16px to prevent wrong em sizes due to tommybooking overrides
  & {
    font-size: 16px;
  }
}

body.hide_tommy_accommodation_groups .tommy-zeb-accommodation-types {
    display: none !important;
}

body.hide_tommy_people_groups .tommy-zeb-person-categories {
    display: none !important;
}


body.w3-booking-widget-fullscreen-active {
	overflow: hidden;

  .mini-sab,
	&:not(.home) .main {
		position: relative;
		z-index: 2147483647;
	}
}