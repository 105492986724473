// vocabulary-term-list
.vocabulary-term-list {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @extend .d-flex;
    @extend .flex-wrap;
  }
}

// list
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  &.list-icons {
    .list-item {
      @extend .position-relative;
      padding: 0 0 0 30px;
      .icon {
        @extend .position-absolute;
        left: 0;
      }
    }
  }
}

// footer-logolink
.footer-logolink {
  .list {
    @extend .d-flex;
    @extend .flex-wrap;
  }
}

// footer-link
.footer-link {
  .list {
    @extend .d-flex;
    @extend .flex-wrap;
  }
}
