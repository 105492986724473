// custom-list
.custom-list {
	padding-left: 20px;

	li {
		margin: 10px 0;
		font-weight: 400;
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		display: flex;
		align-items: center;
		margin: 4px 0;
		padding: 0 0 0 35px;

		&::before {
			content: '\f00c';
			position: absolute;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-size: 24px;
			font-weight: 400;
		}
	}
}
