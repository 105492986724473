.blog-category-filter {
  margin-bottom: 4vh;

  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      margin-right: 15px;
    }
  }
}

.blog-filter {
  margin-bottom: 4vh;

  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      margin-right: 15px;
    }
  }
}
