.btn {
  max-width: 100%;

  // btn-white
  &.btn-white {
    background-color: $white;
    border-color: $white;
    &:hover {
      @extend .btn-primary;
    }
  }
}

// btn-back
.btn-back {
  @extend .mb-3;
}
