.cookie-consent {
  a { text-decoration: none !important; }

  .list {
    position: relative;
    margin: 0;
    padding: 15px;

    border: 1px solid rgba(214, 214, 214, 0.5);
    border-radius: 3px;
    cursor: pointer;
  }

  .list::before {
    position: absolute;
    top: 5px;
    right: 25px;

    font-family: "Font Awesome 5 Pro";
    font-size: 32px;
    content: "\f111";
  }

  .active .list { border: 1px solid rgba(204, 204, 204, 1); }
  .active .list::before { content: '\f058'; }

  .list ul { margin: 0 0 15px; }
  .list ul li { padding-left: 20px; }
}

.cookie-consent-banner {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;

  padding: 15px 0;

  background-color: #ECECEC;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  font-size: 13px;
  text-align: left;
  color: #6F6F6F;

  .cookie-container { display: flex; }
  .cookie-container-left { padding: 0 15px 0 0; }
  .cookie-container-right {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 150px;
    padding: 0 0 0 15px;

    border-left: 1px solid #CCCCCC;
  }

  a.cookie-close span { pointer-events: none; }
  a.cookie-close span.text {
    margin: 0 10px 0 0;
    font-size: 20px;
  }
  a.cookie-close span.icon {
    font-size: 40px;
    font-weight: bold;
  }

  a.cookie-close {
    display: flex;
    align-items: center;
  }
  a:hover { color: #222222; }

  @include media-breakpoint-down (md) {
    .cookie-container { flex-direction: column; }
    .cookie-container-left { padding: 0; }
    .cookie-container-right {
      margin-top: 15px;

      background-color: #D8D8D8;
      border: 1px solid #CCCCCC;
    }
  }
}
