// header
.header {
	z-index: 998;

	@include media-breakpoint-down(sm) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: background-color 0.5s;

		&.sticky {
			background: linear-gradient(105deg, rgba($yellow, 0.95) 0%, rgba($pink, 0.95) 30%, rgba($blue, 0.95) 100%);
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 999;

		@include media-breakpoint-up(md) {
			position: fixed;
			top: 50%;
			left: 15px;
			transform: translateY(-50%);
		}

		.navbar-toggler {
			width: 45px;
			height: 45px;
			padding: 0;
			border: none;
			background: rgba($white, 0.5);
			border-radius: 100%;
			color: $black;
			font-size: 28px;
			font-weight: 400;
			text-transform: uppercase;
			transition: 0.5s;

			@include media-breakpoint-up(md) {
				width: 55px;
				height: 55px;
				background: #f0d1ad;
			}

			i {
				line-height: 45px;

				@include media-breakpoint-up(md) {
					line-height: 55px;
				}
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
						font-size: 34px;
						font-weight: 300;
					}
				}
			}

			&:hover {
				background: $black;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-down(sm) {
			width: 175px;
		}

		@include media-breakpoint-up(md) {
			z-index: 997;
			position: fixed;
			top: 30px;
			left: 30px;
		}

		a {
			display: flex;
			align-items: center;

			img {
				@extend .w-100;
				transition: transform .5s;
			}

			&:hover {
				img {
					transform: scale(1.05);
				}
			}

		}

		&.logo-white {}

		&.logo-color {}
	}

}

&.home {
	@include media-breakpoint-up(sm) {
		.logo {
			animation: delay 1s 0.5s both;
		}
	}
}
