// old collapsable layout
#accommodationAccordion {
  margin-top: 50px;

  .card {
    border: none;
    border-top: 1px solid #e9e3e0;
    border-radius: 0;

    .card-header {
      background: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      padding: 10px 0;

      h2 {
        button {
          box-shadow: none;
          padding: 0;
          font-size: $h2-font-size;
          text-transform: none;
          text-decoration: none;
          color: $body-color;
          font-family: $headings-font-family;
          font-style: italic;
          position: relative;

          &:after {
            content: '\f00d';
            font-family: $font-awesome;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            font-size: 20px;
            pointer-events: none;
            font-style: normal;
            transition: 200ms;
          }

          &[aria-expanded='true'] {
            &:after {
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .collapse {
      p:first-of-type {
      }
    }

    &:last-of-type {
      border-bottom: 1px solid #e9e3e0;
    }
  }
}

// new smooth layout
.accordion-list {
  margin-top: 2rem;
  margin-bottom: 3rem;

  &-item {
    border-top: 1px solid $primary;

    &:last-child {
      border-bottom: 1px solid $primary;
    }

    &-header {
      h2 {
        margin-bottom: 0;
      }

      button {
        padding-left: 0;
        padding-right: 0;
        border: unset;
        position: relative;
        font-weight: 700;
        font-size: 18px;
        text-transform: unset;
        letter-spacing: normal;
        text-decoration: none;
        box-shadow: none;
        background-color: transparent;
        color: $primary;

        &:active,
        &:focus {
          box-shadow: none;
          text-decoration: none;
        }

        &:hover {
          color: inherit;
          text-decoration: none;
        }

        &::after {
          content: '\e833';
          font-family: 'fontello';
          position: absolute;
          right: 0;
        }

        &[aria-expanded="true"] {
          &::after {
            content: '\e83c';
          }
        }
      }
    }

    &-content {
      display: none;

      ul:not(.custom-list) {
        padding-left: 1rem;
      }
    }
  }
}
