.image-collection-photoalbum {
  @include make-row();
  margin: 0 -15px -60px -15px;

  .image-collection-album {
    @include make-col-ready();
    @include make-col(12);
    margin-bottom: 60px;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }

    .gallery {
      margin: 0;
    }
  }
}
