.alert {
  .form-error-icon {
    @extend .d-none;
  }
}

.alert-danger {
  background: $white;
  border-color: $danger;
  color: $danger;
}
