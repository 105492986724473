.form {
  // form-error-icon
  .form-error-icon {
    @extend .d-none;
  }

  // g-recaptcha
  .g-recaptcha {
    margin-bottom: $form-group-margin-bottom;
  }

  // recaptcha
  .recaptcha {
    font-size: 80%;
    line-height: normal;
  }

  // form-success-message
  .form-success-message {
    @extend .alert;
    @extend .alert-warning;
  }
}
