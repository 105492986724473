a.card {
  @extend .text-decoration-none;
}
.card {
  @extend .h-100;
  overflow: hidden;
  &.bg-dark {
    .card-caption {
      color: $text-light;
    }
  }
  &.bg-light {
    .card-caption {
      color: $text-dark;
    }
  }

  // card-image
  .card-image {
    @extend .position-relative;
    flex: 0 0 auto;
    overflow: hidden;
    .card-image-link {
      @extend .d-block;
    }
    .card-img {
      @extend .d-block;
      border-radius: 0;
      transition: 0.5s;
    }
    &:hover {
      .card-img {
        transform: scale(1.05);
      }
    }
    .card-image-caption {
      z-index: 1;
      @extend .position-absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($black, 0.25);
      padding: 0;
      .card-image-label {
        padding: 5px 15px;
        @extend .text-truncate;
        color: $white;
        padding: 5px 15px;
      }
    }
  }

  // card-body
  .card-body {
    @extend .d-flex;
    @extend .flex-column;
    padding: 15px;
  }

  // card-caption
  .card-caption {
    flex: 1 1 auto;
    color: $body-color;
    .card-title-link {
      @extend .d-block;
      margin-bottom: $card-spacer-y;
      color: inherit;
      text-decoration: none;
      .card-title {
        margin-bottom: 0 !important;
      }
      &:hover {
        color: $primary;
      }
    }
    .card-title {
      margin-bottom: $card-spacer-y;
      color: inherit;
      transition: 0.5s;
      &:hover {
        color: $primary;
      }
    }
    .card-subtitle {
      margin-bottom: $card-spacer-y;
      color: inherit;
    }
    .card-description {
      .list {
        margin-top: $card-spacer-y;
        li {
          color: inherit;
        }
      }
    }
  }

  // card-buttons
  .card-buttons {
    flex: 0 0 auto;
    margin-top: 15px;
  }
}

// card > card-overlay
.card.card-overlay {
  // card-image
  .card-image {
    .card-image-caption {
      @extend .d-none;
    }
  }
  .card-img-overlay {
    padding: 15px;
    background: rgba($black, 0.25);
    overflow: hidden;
  }

  // card-caption
  .card-caption {
    color: $white;
    text-shadow: $shadow;
  }

  // card-btn
  .card-btn {
    background: transparent;
    border-color: $white;
    color: $white;
    &:hover {
      background: $white;
      border-color: $white;
      color: $body-color;
    }
  }
}
a.card.card-overlay {
  &:hover {
    .card-img {
      transform: scale(1.05);
    }
  }
}

// card > card-assortiment
.card.card-assortiment {
  .card-caption {
    .card-prices {
      margin-top: $card-spacer-y;
      .card-price {
        font-weight: 700;
        span {
          display: inline-block; /* fix extra spacing */
        }
      }
      .card-price-value {
        &.forsale {
          font-size: $font-size-sm;
          span {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

// card > card-arrangement
.card.card-arrangement {
  .card-image {
    overflow: visible;
    .card-label {
      position: absolute;
      right: 0;
      bottom: -10px;
      margin: 0 15px;
      background: $primary;
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      border-radius: $border-radius;
      color: $white;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 10px;
        width: 0;
        height: 0;
        border-top: 10px solid $primary;
        border-right: 10px solid transparent;
      }
    }
  }
  .card-info {
    margin-bottom: 1rem;

    .card-staydata {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 0.5rem 0;
      padding: 0;
      list-style: none;
      li {
        font-size: $font-size-base * 0.75;
        line-height: 1;
        + li {
          &::before {
            content: '|';
            margin: 0 5px;
          }
        }
      }
    }
    .card-traveldata {
      margin-bottom: 1rem;
      .dates {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0.5rem 0;
        padding: 0;
        list-style: none;
        li {
          color: $primary;
          line-height: 1;
          font-size: $font-size-sm;
          + li {
            &::before {
              content: '\f061';
              margin: 0 7.5px;
              color: rgba($primary, 0.5);
              font-weight: 900;
              font-family: $font-awesome;
            }
          }
        }
      }
    }
    .card-prices {
      text-align: right;
      .card-old-price {
        font-size: $font-size-sm;
        line-height: 1;
        text-decoration: line-through;
      }
      .card-price {
        .card-price-prefix {
          font-size: $font-size-base * 0.75;
        }
        .card-price-amount {
          margin-left: 5px;
          color: $primary;
          font-size: $font-size-lg;
          font-weight: 700;
        }
      }
    }
  }
  .card-characteristics {
    margin: 0;
  }
  .card-buttons {
    margin: 0;
  }
}

// card > card-wide
.card.card-wide {
  @extend .flex-row;
  @extend .flex-wrap;

  .card-image {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(5);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }

  .card-body {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(7);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(8);
    }
  }
}

// card > card-icon
.card.icon {
  .card-image {
    @extend .text-center;
  }
}

// parent behaviour
.inspiration {
  .card.icon {
    .card-image {
      @extend .h-100;

      .card-image-link {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;

        @extend .h-100;
      }
    }
  }
}
