.menu {
  // menu
  &.menu {
    @include media-breakpoint-up(xl) {
      .dropdown-menu {
        border-color: #e1e1e1;
        border-radius: 3px;
        box-shadow: 0 0 6px rgba($black, 0.15);
        top: 100% !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        &.show {
          &::before {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: 0 auto;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 10px solid #e1e1e1;
          }
          &::after {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: 0 auto;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #fff;
          }
        }
      }
    }
  }

  // todo: check side-panel
  // side panel
  &.side-panel {
    &.side-panel-opened {
      .nav-item.active-side-panel {
        display: inherit;

        .dropdown-toggle {
          display: none !important;
        }

        .nav-item {
          display: inherit;
        }
      }
      .nav-item:not(.active-side-panel) {
        display: none;
      }
    }

    .menu-side-panel-header {
      .go-back::after {
        content: "\F060";
        font-family: "Font Awesome 5 Pro";
        font-size: 0.813rem;
        font-weight: 700;
        border: none;
      }
    }
  }
}
