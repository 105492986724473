// social-share
.social-share {
  text-align: center;
  .share-btn {
    display: inline-block;
    margin: 1px;
    padding: 3px 15px;
    border-radius: 0.25rem;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    &.twitter {
      background: rgba(85, 172, 238, 1);
      &:hover {
        background: rgba(85, 172, 238, 0.75);
      }
    }
    &.facebook {
      background: rgba(24, 119, 242, 1);
      &:hover {
        background: rgba(24, 119, 242, 0.75);
      }
    }
    &.linkedin {
      background: rgba(0, 119, 181, 1);
      &:hover {
        background: rgba(0, 119, 181, 0.75);
      }
    }
    &.email {
      background: rgba(125, 125, 125, 1);
      &:hover {
        background: rgba(125, 125, 125, 0.75);
      }
    }
    &.pinterest {
      background: rgba(203, 32, 39, 1);
      &:hover {
        background: rgba(203, 32, 39, 0.75);
      }
    }
    &.whatsapp {
      background: rgba(37, 211, 102, 1);
      &:hover {
        background: rgba(37, 211, 102, 0.75);
      }
    }
    &.print {
      background: rgba(34, 34, 34, 1);
      &:hover {
        background: rgba(34, 34, 34, 0.75);
      }
    }
    img {
      width: 30px;
    }
  }
}
