.page-block.resource-image {
  a {
    text-decoration: none;

    .image-holder-image {
      overflow: hidden;

      img {
        transition: 0.5s;
        width: 100%;
      }
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    .image-holder-caption {
      background: #fff;
      padding: 15px;
    }
  }
}
