.loading-icon {
  &::before {
    @extend .d-block;
    width: 32px;
    height: 32px;
    margin: 0 auto;

    border: 4px solid $gray-100;
    border-color: $secondary $secondary $secondary transparent;
    border-radius: 50%;

    content: '';
    animation: loading-icon-spin .75s linear infinite;
  }
}

.loading-overlay {
  @extend .position-relative;

  @extend .loading-icon;
  &::before {
    @extend .position-absolute;
    top: 50%;
    left: 50%;

    margin: -16px 0 0 -16px;
  }
}

@keyframes loading-icon-spin {
  to { transform: rotate(360deg); }
}
