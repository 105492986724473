.collection {

	// grid
	&.grid {
		.grid-items {
			justify-content: flex-start;

			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					@include make-col(6);
				}
			}
		}
	}
}
