// match both .container and .container-* class selector.
.container,
[class*="container-"] {
  // default container-holder behaviour for .container and .container- aliases (non-boxed).
  .container-holder {
    @include make-row();

    // stack column content as a flex-column.
    > .column {
      display: flex;
      flex-flow: column nowrap;
    }
  }
}

// container-one-column
.container-one-column {
  .container-holder {
    > .column.default {
      @include make-col-ready();
      @include make-col(12);
    }
  }
}

// container-two-columns
.container-two-columns {
  .container-holder {
    margin-bottom: -15px;
    > .column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      margin-bottom: 15px;
    }

    > .column.one {
      @extend .pr-md-4;
      @extend .pr-lg-5;
    }

    > .column.two {
      @extend .pl-md-4;
      @extend .pl-lg-5;
    }
  }
}

// container-three-columns
.container-three-columns {
  .container-holder {
    margin-bottom: -15px;
    > .column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
      margin-bottom: 15px;
    }
  }
}

// container-four-columns
.container-four-columns {
  .container-holder {
    margin-bottom: -15px;
    > .column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
      margin-bottom: 15px;
    }
  }
}

// container-six-columns
.container-six-columns {
  .container-holder {
    margin-bottom: -15px;
    > .column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(2);
      }
      margin-bottom: 15px;
    }
  }
}

// container- without .container specific, remove row margin
[class*="container-"] {
  > .container-holder {
    .column {
      &.col-1 {
        @include make-col-ready();
        @include make-col(1);
      }
      &.col-2 {
        @include make-col-ready();
        @include make-col(2);
      }
      &.col-3 {
        @include make-col-ready();
        @include make-col(3);
      }
      &.col-4 {
        @include make-col-ready();
        @include make-col(4);
      }
      &.col-5 {
        @include make-col-ready();
        @include make-col(5);
      }
      &.col-6 {
        @include make-col-ready();
        @include make-col(6);
      }
      &.col-7 {
        @include make-col-ready();
        @include make-col(7);
      }
      &.col-8 {
        @include make-col-ready();
        @include make-col(8);
      }
      &.col-9 {
        @include make-col-ready();
        @include make-col(9);
      }
      &.col-10 {
        @include make-col-ready();
        @include make-col(10);
      }
      &.col-11 {
        @include make-col-ready();
        @include make-col(11);
      }
      &.col-12 {
        @include make-col-ready();
        @include make-col(12);
      }
    }
  }
}
