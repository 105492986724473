.collection {
  // grid
  &.grid {
    .grid-items {
      @include make-row();
      justify-content: center;

      margin: 0 -15px -30px -15px;

      .item {
        @include make-col-ready();
        @include make-col(12);

        margin: 0 0 30px 0;

        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }
    }
  }

  // masonry
  &.masonry {
    .card-columns {
      column-count: 1;
      column-gap: 30px;
      .card {
        margin: 0 0 30px 0;
      }
      @include media-breakpoint-up(sm) {
        column-count: 2;
      }
      @include media-breakpoint-up(md) {
        column-count: 2;
      }
      @include media-breakpoint-up(lg) {
        column-count: 3;
      }
      @include media-breakpoint-up(xl) {
        column-count: 3;
      }
    }
  }

  // inspiration
  &.inspiration {
    @include make-row();

    margin: 0 -15px -30px -15px;

    .item {
      @include make-col-ready();
      @include make-col(12);

      margin: 0 0 30px 0;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
    }
  }
}
