@import '../1_vendor/bootstrap-datepicker';

.timeslot-overview .timeslot-overview-row,
.timeslot-category .timeslot-category-row,
.timeslot-highlight .timeslot-highlight-row {
  margin: 0 -15px -30px -15px;
}

.timeslot-overview {
  margin: 0 0 30px 0;

  .timeslot-overview-row {
    @include make-row();
    justify-content: flex-start;
  }
}

.timeslot-category {
  @include make-col-ready();
  @include make-col(12);

  margin: 0 0 30px 0;

  .timeslot-category-row {
    @include make-row();
    justify-content: flex-start;
  }

  .timeslot-category-content {
    @include make-col-ready();
    @include make-col(12);

    margin: 0 0 30px 0;

    .timeslot-category-title {
      margin: 0 0 $headings-margin-bottom 0;
      font-family: $headings-font-family;
      font-size: $h2-font-size;
      font-style: normal;
      font-weight: $headings-font-weight;
      line-height: $headings-line-height;
      text-transform: none;
      text-decoration: none;
      color: $body-color;
    }
  }
}

.timeslot-highlight {
  margin: 0 0 30px 0;

  .timeslot-highlight-row {
    @include make-row();
    justify-content: flex-start;
  }
}

.timeslot-datepicker-holder,
.timeslot-item-holder {
  @include make-col-ready();
  @include make-col(12);

  margin: 0 0 30px 0;

  @include media-breakpoint-up(sm) {
    @include make-col(12);
  }

  @include media-breakpoint-up(md) {
    @include make-col(6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }

  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }
}

.timeslot-datepicker {
  height: 100%;
  padding: 15px;
  background: $primary;
  border: 1px solid $primary;
  border-radius: $border-radius;
  box-shadow: none;

  .datepicker {
    width: 100%;
    height: 100%;
    padding: 15px;

    background: $white;
    border: 1px solid $white;
    border-radius: $border-radius;

    .datepicker-days,
    .datepicker-months,
    .datepicker-years,
    .datepicker-decades,
    .datepicker-centuries {
      height: 100%;
    }

    .table-condensed {
      width: 100%;
      height: 100%;
    }

    table {
      tr {
        td {

          &.day.active,
          span.active {
              background: $primary;
              color: $body-color;
          }
        }
      }
    }
  }
}

.timeslot-item {
  height: 100%;
  padding: 15px;

  background: $white;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  box-shadow: none;

  .timeslot-item-title {
    margin: 0 0 $headings-margin-bottom 0;
    font-family: $headings-font-family;
    font-size: $h3-font-size;
    font-style: normal;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    text-transform: none;
    text-decoration: none;
    color: $body-color;
  }

  .timeslot-item-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    background: transparent;

    .timeslot-item-navigation-duration {
      float: 1 1 auto;
      padding: 0 10px;
      text-align: center;
    }

    .timeslot-item-navigation-btn {
      @extend .btn;
      padding: 5px 10px;

      flex: 0 0 auto;
      background-color: $primary;
      border: 1px solid $primary;
      border-radius: $btn-border-radius;
      box-shadow: none;

      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-base;
      color: $white;

      &:hover {
        background-color: $secondary;
        border: 1px solid $secondary;
        color: $white;
      }
    }
  }

  .timeslot-item-days {
    margin: -1px 0 0 0;

    .timeslot-item-day {
      @include make-row();
      margin: 1px -.5px 0 -.5px;

      .timeslot-item-day-title-holder,
      .timeslot-item-day-times-holder {
        @include make-col(12);
        padding: 0 .5px 0 .5px ;

        @include media-breakpoint-up(sm) {
          @include make-col(12);
        }

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }

      .timeslot-item-day-title {
        height: 100%;
        padding: 5px 10px;
        background: $gray-300;

        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: $line-height-base;
        text-transform: none;
        text-decoration: none;
        color: $body-color;
      }

      .timeslot-item-day-times {
        height: 100%;
        padding: 5px 10px;
        background: $gray-200;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-base;
        line-height: $line-height-base;
        text-transform: none;
        text-decoration: none;
        color: $body-color;

        .timeslot-item-day-times-list {
          margin: 0;
          padding: 0;

          list-style: none;

          &::before,
          &::after {
            display: none;
          }

          .timeslot-item-day-times-list-item {
            font-size: inherit;
            font-weight: inherit;
            text-transform: inherit;
            text-decoration: inherit;
            color: inherit;
          }
        }

        .timeslot-item-day-times-info {
          position: relative;

          .timeslot-item-day-times-info-popup {
            position: absolute;
            top: 30px;
            right: unset;
            bottom: unset;
            left: 50%;

            display: none;
            width: 250px;
            margin: 0 0 0 -125px;
            padding: 15px;

            background: $white;
            border: 1px solid $white;
            border-radius: $border-radius;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

            font-size: $font-size-base * .875;
            line-height: $line-height-base;
            color: $body-color;

            & > * {
              font-size: inherit;
              color: inherit;
            }

            :last-child {
              margin: 0;
            }

            &::before {
              position: absolute;
              top: unset;
              right: unset;
              bottom: 100%;
              left: 50%;

              width: 0;
              height: 0;
              margin: 0 0 0 -10px;

              border: 10px solid transparent;
              border-top-color: transparent;
              border-right-color: transparent;
              border-bottom-color: $white;
              border-left-color: transparent;

              content: '';
            }
          }

          &:hover {
            .timeslot-item-day-times-info-popup {
              display: block;
            }
          }
        }
      }
    }
  }
}
