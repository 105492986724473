// footer
.footer {
	@extend .bg-dark;
	@extend .section-padding-x;
	display: flex;
	flex-direction: column;
	padding-top: 8vh;
	padding-bottom: 40px;
	@include gradient;
	@include gradient-animation;

	@include media-breakpoint-up(xl) {
		min-height: 100vh;
		padding-top: 160px;
	}

	strong {
		font-weight: 700;
	}

	// footer-outro
	.footer-outro {
		margin-bottom: 8vh;

		.container-one-column {
			.container-holder {
				max-width: 750px;
			}
		}

		a.link {
			@extend .btn;
			@extend .btn-primary;
			color: $white !important;
			text-decoration: none !important;
		}
	}

	// footer-address
	.footer-address {
		margin: auto 0 8vh 0;
		font-size: 18px;
	}

	// footer-copyright
	.footer-copyright {
		.container-holder {
			align-items: flex-end;

			>.column {
				@include make-col(6);
			}
		}

		.footer-text {
			ul {
				display: flex;
				flex-flow: row wrap;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				margin: 0;
				padding: 0;
				list-style: none;

				li {
					font-size: 14px;

					@include media-breakpoint-up(md) {
						+li {
							&::before {
								content: "/";
								margin: 0 5px;
							}
						}
					}

					a {
						color: $white;
						text-decoration: none !important;
					}
				}
			}
		}

		.footer-logolink {
			margin-left: auto;

			.list {
				justify-content: center;

				.list-item {
					+li {
						margin-left: 20px;
					}

					.link {
						display: block;
						font-size: 24px;
					}
				}
			}
		}
	}
}
